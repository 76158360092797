import axios from "axios";
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Swiper from 'react-id-swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function TrendingProducts() {

    const [trendingProductsData, setTrendingProductsData] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    useEffect(() => {
        loadTrendingProducts();
    },[]);

    function loadTrendingProducts() {
        axios.get(process.env.REACT_APP_API_URL + "products/trending")
        .then(response => 
            {
                setTrendingProductsData(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    const trendingProductsParams = {
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        shouldSwiperUpdate: true,
        centeredSlides: true,
        observer: true,
        loop: true,
        spaceBetween: 30,
        rebuildOnUpdate: true,
        breakpoints: {
            1200: {
                slidesPerView: 4
            },
            990: {
                slidesPerView: 3
            },
            768: {
                slidesPerView: 2
            },
            540: {
                slidesPerView: 1
            },
            390: {
                slidesPerView: 1
            }
        }
    }

    return (
        <div>
            <section className="trending-product-section">
                <div className="container">
                    <div className="section-heading">
                        <h4 className="heading-title"><span className="heading-circle"></span> Trending Products</h4>
                    </div>
                    <div className="section-wrapper">
                        <div className="mlr-20">
                            <div className="trending-product-container">
                                    <Swiper {...trendingProductsParams}>
                                        {trendingProductsData.map((trendingProduct, index) => (
                                            <div className="swiper-slide" key={trendingProduct.productId}>
                                                <div className="product-item">
                                                    <div className="product-thumb">
                                                        {trendingProduct.productImages.length > 0 && trendingProduct.productImages.filter(p=>p.imageType == 1).length > 0 ? trendingProduct.productImages.map((productImage, index) => {
                                                        if(productImage.imageType == 1) {
                                                            return (
                                                                <Link to={"/productdetails/" + trendingProduct.productId} style={{'height': '255px', 'width': '255px'}}>
                                                                    <img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/" + productImage.imageName} alt={trendingProduct.name}></img>
                                                                </Link>
                                                                    )
                                                        }
                                                        else {
                                                            return null;
                                                        }
                                                        }): <Link to={"/productdetails/" + trendingProduct.productId} style={{'height': '255px', 'width': '255px'}}>
                                                            <img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/no_image.png"} alt={trendingProduct.name}></img>
                                                        </Link>}
                                                        
                                                    </div>
                                                    <div className="product-content">
                                                        <h6>
                                                            <Link to={"/productdetails/" + trendingProduct.productId} className="product-title">
                                                                {trendingProduct.name}
                                                            </Link>
                                                        </h6>
                                                        <p className="quantity">QTY: 1</p>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="price"><FontAwesomeIcon icon={faRupeeSign} /> {
                                                                cookies.DealerDetails != undefined ? trendingProduct.dealerPrice : trendingProduct.retailPrice
                                                            }</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Swiper>
                            </div>
                            <div className="text-center pt-3">
                                <Link to="/products" className="more-product-btn">
                                    More Products
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default TrendingProducts;