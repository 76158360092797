import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMobileAlt, faEnvelope, faGlobe, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import React, { useState, useEffect, useMemo } from 'react';

function ContactUs() {
    const [contactEnquiry, setContactEnquiry] = useState({name: '', email: '', phone: '', message: ''});
    const [contactEnquirySubmitDisabled, setContactEnquirySubmitDisabled] = useState(true);
    const [webUtility, setWebUtility] = useState({contactAddress: '', contactEmail: '', contactPhone: ''});
    const [dealersData, setDealersData] = useState([]);

    const handleChange = e => {
        const { name, value, checked } = e.target;

        setContactEnquiry(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));
    };

    useEffect(() => {
        loadWebUtilities();
        loadDealersForContact();
    },[]);

    useMemo(() => {
        if (contactEnquiry.phone != undefined && contactEnquiry.phone.length > 0 && contactEnquiry.name != undefined && contactEnquiry.name.length > 0 && contactEnquiry.message != undefined && contactEnquiry.message.length > 0) {
            setContactEnquirySubmitDisabled(false);
        }
        else{
            setContactEnquirySubmitDisabled(true);
        }
    }, [contactEnquiry]);

    function loadDealersForContact() {
        axios.get(process.env.REACT_APP_API_URL + "dealers/dealersforcontactus")
        .then(response => 
            {
                if(response.data.data) {
                    setDealersData(response.data.data);
                }
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function loadWebUtilities() {
        axios.get(process.env.REACT_APP_API_URL + "webutility")
        .then(response => 
            {
                if(response.data.data) {
                    setWebUtility(response.data.data);
                }
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function submitContactEnquiry() {
        setContactEnquirySubmitDisabled(true);
        axios.post(process.env.REACT_APP_API_URL + "contacts/add", {
            Name: contactEnquiry.name,
            Email: contactEnquiry.email,
            Phone: contactEnquiry.phone,
            Message: contactEnquiry.message
          })
          .then((response) => {
            setContactEnquirySubmitDisabled(false);
            if(response != null && response.data.success == true) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                setContactEnquiry({name: '', email: '', phone: '', message: ''});
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
          }).catch(error => { // your error handling goes here};
            setContactEnquirySubmitDisabled(false);
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    return (
        <div>
            <div className="page-header-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between justify-content-md-end">
                            <ul className="breadcrumb">
                                <li><a href="/">Home</a></li>
                                <li><span>/</span></li>
                                <li>Contact Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <section className="contact-section section-ptb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 mb-4 mb-lg-0">
                            <div className="contact-info-wrapper">
                                <div className="contact-info">
                                    <h4>Get In Touch</h4>
                                    <ul class="contact-details">
                                        <li>
                                            <span className="title">Address</span>
                                            <span className="desc">{webUtility.contactAddress}</span>
                                        </li>
                                        <li>
                                            <span className="title">Phone</span>
                                            <span className="desc">{webUtility.contactPhone}</span>
                                        </li>
                                        <li>
                                            <span className="title">Email</span>
                                            <span className="desc">{webUtility.contactEmail}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="contact-info mt--20">
                                    <h4>Dealers</h4>
                                    <ul class="contact-details">
                                        {dealersData.map((dealer, i) => 
                                            <li>
                                                <span className="title">{dealer.city}</span>
                                                <span className="desc">{dealer.name} <br/> {dealer.address + ', ' + dealer.city + ', ' + dealer.state} <br/> {'Phone: ' + dealer.phone}</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contact-form-area">
                                <form action="#" className="contact-form">
                                    <div className="input-item">
                                        <input type="text" maxLength="100" name="name" placeholder="Full Name" onChange={handleChange} value={contactEnquiry.name}></input>
                                        <i className="contact-icon"><FontAwesomeIcon icon={faUser} /></i>
                                    </div>

                                    <div className="input-item">
                                        <input type="number" maxLength="10" name="phone" placeholder="Your Phone Number" onChange={handleChange} value={contactEnquiry.phone}></input>
                                        <i className="contact-icon"><FontAwesomeIcon icon={faMobileAlt} /></i>
                                    </div>

                                    <div className="input-item">
                                        <input type="email" name="email" maxLength="100" placeholder="Email Address" onChange={handleChange} value={contactEnquiry.email}></input>
                                        <i className="contact-icon"><FontAwesomeIcon icon={faEnvelope} /></i>
                                    </div>
                                    <div className="input-item">
                                        <textarea name="message" maxLength="500" placeholder="Type Here Message" onChange={handleChange} value={contactEnquiry.message}></textarea>
                                        <i className="contact-icon"><FontAwesomeIcon icon={faPaperPlane} /></i>
                                    </div>
                                    <div>
                                        <button type="submit" className="submit" onClick={submitContactEnquiry} disabled={contactEnquirySubmitDisabled}>Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default ContactUs;