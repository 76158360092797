import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Swiper from 'react-id-swiper';
import TrendingProducts from '../../components/products/trending-products.js';
import RecommendedProducts from '../../components/products/recommended-products.js';

function Landing() {

    const swiperParams = {
        loop: true,
        centeredSlides: true,
        shouldSwiperUpdate: true,
        centeredSlides: true,
        rebuildOnUpdate: true,
        observer: true,
        spaceBetween: 30,
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
          },
        breakpoints: {
            1200: {
                slidesPerView: 2
            },
            990: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 1
            },
            390: {
                slidesPerView: 1
            }
        }
    }

    return (
        <div>
            <section className="banner-section bg-img3 d-flex align-items-center">
                <div className="banner-content-area">
                    <div className="container">
                        <div className="banner-content">
                            <h6>Best and Reliable</h6>
                            <h2>Get quality product delivered<br></br>on your doorstep.</h2>
                            <a href="/products" className="banner-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="info-box-section">
                <div className="container">
                    
                            <Swiper {...swiperParams}>
                                <div className="swiper-slide">
                                    <div className="info-box-item d-sm-flex text-center text-sm-left">
                                        <div className="info-icon">
                                            <img src="../info.svg" alt="info icon"></img>
                                        </div>
                                        <div className="info-content">
                                            <h6>Place order</h6>
                                            <p>Visit our products catalog. Get the best suitable product. Call us to place your order.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="info-box-item d-sm-flex text-center text-sm-left">
                                        <div className="info-icon">
                                            <img src="../info.svg" alt="info icon"></img>
                                        </div>
                                        <div className="info-content">
                                            <h6>Replacement Guarantee</h6>
                                            <p>Every product comes with guarantee. We will replace with new product.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="info-box-item d-sm-flex text-center text-sm-left">
                                        <div className="info-icon">
                                            <img src="../info.svg" alt="info icon"></img>
                                        </div>
                                        <div className="info-content">
                                            <h6>Fast Delivary</h6>
                                            <p>We serve the best service to our customers. You will get your order delievered ASAP.</p>
                                        </div>
                                    </div>
                                </div>
                            </Swiper>
                        
                </div>
            </section>
            <TrendingProducts></TrendingProducts>
            <div className="advertisement-section pb-5">
                <div className="container">
                    <div className="row mb-4">
                        <div className="col-lg-6">
                            <div class="info-box-item d-sm-flex text-center text-sm-left">
                                <div class="info-icon">
                                    <img src="../info.svg" alt="info icon"></img>
                                </div>
                                <div class="info-content">
                                    <h6>Value Your Money</h6>
                                    <p>Visit our products catalog. Get the best suitable product. Call us to place your order.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                        <div class="info-box-item d-sm-flex text-center text-sm-left">
                                <div class="info-icon">
                                    <img src="../info.svg" alt="info icon"></img>
                                </div>
                                <div class="info-content">
                                    <h6>Meets Your Requirement</h6>
                                    <p>Visit our products catalog. Get the best suitable product. Call us to place your order.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RecommendedProducts></RecommendedProducts>
            <div className="advertisement-section pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <a><img src="../gfs_200.png" alt="advertise"></img></a>
                        </div>
                        <div className="col-lg-4">
                            <a><img src="../PTS_200.png" alt="advertise"></img></a>
                        </div>
                        <div className="col-lg-4">
                            <a><img src="../KK_200.png" alt="advertise"></img></a>
                        </div>
                    </div>
                </div>
            </div>
            <section className="testimonial-section">
                <div className="container">
                    <div className="section-heading">
                        <h4 className="heading-title"><span className="heading-circle"></span>Testimonial</h4>
                    </div>

                    <div className="section-wrapper">
                        <div className="testimonial-container">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-body">
                                        <div className="testi-author-pic"><img src="../author.png" alt="author"></img></div>
                                        <p className="desc">It was a very good experience with Green Farm Solutions. Best product with best quality. I would say Green Farm Solutions is the best place if you are looking for quality with best service.</p>
                                        <div className="author-info">
                                            <h6 className="name">Ashok Patel</h6>
                                            <div className="rating">
                                                <FontAwesomeIcon icon={faStar} style={{color: '#f9d300'}} />
                                                <FontAwesomeIcon icon={faStar} style={{color: '#f9d300'}}/>
                                                <FontAwesomeIcon icon={faStar} style={{color: '#f9d300'}}/>
                                                <FontAwesomeIcon icon={faStar} style={{color: '#f9d300'}}/>
                                                <FontAwesomeIcon icon={faStar} style={{color: '#f9d300'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Landing;