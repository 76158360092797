import Header from '../../components/header/header.js';
import Footer from '../../components/footer/footer.js';
import Landing from '../../components/landing/landing.js';
import NotFound from '../../components/notfound/notfound.js';
import AllProducts from '../../components/products/product-list.js';
import ProductDetails from '../../components/products/product-details.js';
import ContactUs from '../../components/contactus/contact-us.js';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from '../../components/aboutus/aboutus.js';


function Home() {
    
    return (
        <div>
            <Header></Header>
            <div className="page-layout" id="page-layout">
                <div className="main-content-area">
                    <Switch>
                        <Route path="/" exact component={Landing}></Route>
                        <Route path="/products" exact component={AllProducts}></Route>
                        <Route path="/productdetails/:productId" exact component={ProductDetails}></Route>
                        <Route path="/contactus" exact component={ContactUs}></Route>
                        <Route path="/aboutus" exact component={AboutUs}></Route>
                        <Route component={NotFound}></Route>
                    </Switch>
                    <Footer></Footer>
                </div>
            </div>
        </div>
    );
}
export default Home;