import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/styles/style.css';
import './assets/styles/animate.css';
import './assets/styles/bootstrap.min.css';
import './assets/sass/style.scss';
import './assets/js/functions.js';
//import './assets/js/jquery.min.js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from "react-cookie";
// import 'bootstrap/dist/css/bootstrap.min.css';


//const $ = require("jquery")(window);



ReactDOM.render(
  <React.StrictMode>
      <CookiesProvider>
        <App />
      </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
