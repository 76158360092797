
function AboutUs() {
    return (
        <div>
            <section className="about-section section-ptb">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 order-lg-last pr-xl-5">
                            <div className="about-content mb-4 mb-lg-0 pr-lg-5">
                                <h3>Who We Are</h3>
                                <p>We are well known in automation technologies and with the back of extremely fine quality products like all kinds of digital starters for industrial and agriculture use, we are winning our costumers’ hearts, since long time. GREEN FARM SOLUTIONS is successful in making a unique class image on market with its unprecedented polite support and service.</p>
                                <p>To leave the very good image on every consumer of our quality products. To make the routine work more comfortable by bringing new automation technologies into market. To transform the way of business by providing extreme satisfaction, service and support to costumers. To make all quality products available for customers by providing cost effectiveness.</p>
                                <p>GREEN FARM SOLUTIONS will continue to focus on being a technology driven company. Our strength is and will continue to be our ability to stay at the forefront of the technology.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-lg-first">
                            <div className="about-image">
                                <img src="../aboutus.png" alt="green farm solutions"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default AboutUs;