import axios from "axios";
import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { css } from "jquery";
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function ProductDetails() {
    const params = useParams();
    const [productData, setProductData] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [slider1, setSlider1] = useState();
    const [slider2, setSlider2] = useState();
    const [cookies] = useCookies(['name']);
    const [productEnquiry, setProductEnquiry] = useState({name: '', email: '', phone: '', message: ''});
    const [productEnquirySubmitDisabled, setProductEnquirySubmitDisabled] = useState(true);

    useEffect(() => {
        loadProductDetails();
        loadProductImages();
    },[]);

    const handleChange = e => {
        const { name, value, checked } = e.target;

        setProductEnquiry(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));
    };

    useMemo(() => {
        if (productEnquiry.phone != undefined && productEnquiry.phone.length > 0 && productEnquiry.name != undefined && productEnquiry.name.length > 0 && productEnquiry.message != undefined && productEnquiry.message.length > 0) {
            setProductEnquirySubmitDisabled(false);
        }
        else{
            setProductEnquirySubmitDisabled(true);
        }
    }, [productEnquiry]);

    function loadProductDetails() {
        axios.get(process.env.REACT_APP_API_URL + "products/" + params.productId)
        .then(response => 
            {
                setProductData(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function loadProductImages() {
        axios.get(process.env.REACT_APP_API_URL + "productimages/product/" + params.productId)
        .then(response => 
            {
                setProductImages(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    const productSliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: slider2,
        className: 'product-slick'
    };
    
    const productNavbarSliderSettings = {
        vertical: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        asNavFor: slider1,
        arrows: true,
        dots: false,
        focusOnSelect: true,
        infinite: false,
        className: 'slider-nav'
    };

    function submitProductEnquiry() {
        setProductEnquirySubmitDisabled(true);
        axios.post(process.env.REACT_APP_API_URL + "productenquiries/add", {
            ProductId: productData.productId,
            Name: productEnquiry.name,
            Email: productEnquiry.email,
            Phone: productEnquiry.phone,
            Message: productEnquiry.message
          })
          .then((response) => {
            setProductEnquirySubmitDisabled(false);
            if(response != null && response.data.success == true) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                setProductEnquiry({name: '', email: '', phone: '', message: ''});
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
          }).catch(error => { // your error handling goes here};
            setProductEnquirySubmitDisabled(false);
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    return (
        <div>
            <div className="page-header-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between justify-content-md-end">
                            <ul className="breadcrumb">
                                <li>
                                    <Link to={"/"}>
                                        Home
                                    </Link>
                                </li>
                                <li><span>/</span></li>
                                <li>
                                    <Link to={"/products"}>
                                        Products
                                    </Link>
                                </li>
                                <li><span>/</span></li>
                                <li>Product Details</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <section className="product-zoom-info-section section-ptb">
                <div className="container">
                    <div className="product-zoom-info-container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="product-zoom-area">
                                    <Slider {...productSliderSettings} ref={slider1 => (setSlider1(slider1))}>
                                        {productImages != undefined && productImages.length > 0 ? productImages.map((productImage, index) => {
                                            return (<div key={productImage.productId}><img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/" + productImage.imageName} alt={productImage.name}
                                            className="img-fluid blur-up lazyload image_zoom_cls-1"></img></div>)
                                        }): (<div><img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/no_image.png"} alt={productData.name}
                                            className="img-fluid blur-up lazyload image_zoom_cls-1"></img></div>)
                                        }
                                    </Slider>
                                    <div className="row">
                                        <div className="col-12">
                                            <Slider {...productNavbarSliderSettings} ref={slider2 => (setSlider2(slider2))}>
                                                {productImages != undefined && productImages.length > 0 ? productImages.map((productImage, index) => {
                                                    return (<div key={productImage.productId}><img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/" + productImage.imageName} alt={productImage.name}
                                                    className="img-fluid blur-up lazyload"></img></div>)
                                                }): (<div><img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/no_image.png"} alt={productData.name}
                                                    className="img-fluid blur-up lazyload"></img></div>)
                                                }
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="product-details-content">
                                    <h2>{productData.name}</h2>
                                    <p className="quantity">QTY: 1</p>
                                            <h3 className="price"><FontAwesomeIcon icon={faRupeeSign} /> {
                                                cookies.DealerDetails != undefined ? productData.dealerPrice : productData.retailPrice
                                            }</h3>
                                    <p>{productData.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="description-review-area section-ptb">
                <div class="container">
                    <div class="description-review-wrapper">
                        <div class="description-review-topbar nav">
                            <a class="active" data-toggle="tab" href="#des-details1">Description</a>
                            <a data-toggle="tab" href="#des-details3" class="">Delivery & Return</a>
                            <a data-toggle="tab" href="#des-details2" class="">Product Enquiry</a>
                        </div>
                        <div class="tab-content description-review-bottom">
                            <div id="des-details1" class="tab-pane active">
                                <div class="product-description-wrapper" dangerouslySetInnerHTML={{ __html: productData.longDescription }}>
                                    
                                </div>
                            </div>
                            <div id="des-details2" class="tab-pane">
                                <div class="product-anotherinfo-wrapper">
                                    <div class="review-comments-section">
                                        <h3>Ask your questions related to this product. We will answer you as soon as possible.</h3>
                                        <div class="review-form">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="input-item">
                                                        <label>Name *</label>
                                                        <input type="text" maxLength="100" name="name" onChange={handleChange} value={productEnquiry.name}></input>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="input-item">
                                                        <label>Email</label>
                                                        <input type="text" maxLength="100" name="email" onChange={handleChange} value={productEnquiry.email}></input>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="input-item">
                                                        <label>Phone *</label>
                                                        <input type="number" maxLength="10" name="phone" onChange={handleChange} value={productEnquiry.phone}></input>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="input-item">
                                                        <label>Message *</label>
                                                        <textarea name="message" maxLength="500" onChange={handleChange} value={productEnquiry.message}></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <button type="submit" class="review-submit" onClick={submitProductEnquiry} disabled={productEnquirySubmitDisabled}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="des-details3" class="tab-pane">
                                <div class="product-anotherinfo-wrapper" dangerouslySetInnerHTML={{ __html: productData.deliveryReturn }}>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default ProductDetails;