import axios from "axios";
import React, { useState, useMemo, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faTimes, faUser, faEye, faEyeSlash, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { bundle } from 'bootstrap';
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
//import '../../assets/js/functions.js';

function Header() {
    const history = useHistory();
    const [showForgotDealerCode, setShowForgotDealerCode] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [dealerDetails, setDealerDetails] = useState({phone: '', dealerCode: ''});
    const [requestCodeDetails, setRequestCodeDetails] = useState({phone: ''});
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [submitCodeDisabled, setSubmitCodeDisabled] = useState(true);
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const [webPhone, setWebPhone] = useState('');

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "webutility")
        .then(response => 
            {
                if(response.data.data) {
                    setWebPhone(response.data.data.webPhone);
                }
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    },[]);

    function openSignInForm() {
        document.getElementById("login-area").classList.add('open-form');
    }

    function closeSignInForm() {
        document.getElementById("login-area").classList.remove('open-form');
    }

    const handleChange = e => {
        const { name, value, checked } = e.target;

        setDealerDetails(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));
    };

    const handleCodeChange = e => {
        const { name, value, checked } = e.target;

        setRequestCodeDetails(prevState => ({
            ...prevState,
            [name]: e.target.type === "checkbox" ? checked : value 
        }));
    };

    useMemo(() => {
        if (dealerDetails.phone != undefined && dealerDetails.phone.length > 0 && dealerDetails.dealerCode != undefined && dealerDetails.dealerCode.length > 0) {
            setSubmitDisabled(false);
        }
        else{
           setSubmitDisabled(true);
        }
    }, [dealerDetails]);

    useMemo(() => {
        if (requestCodeDetails.phone != undefined && requestCodeDetails.phone.length > 0) {
            setSubmitCodeDisabled(false);
        }
        else{
            setSubmitCodeDisabled(true);
        }
    }, [requestCodeDetails]);

    function dealerSignIn() {
        setSubmitDisabled(true);
        axios.post(process.env.REACT_APP_API_URL + "dealers/login", {
            Phone: dealerDetails.phone,
            DealerCode: dealerDetails.dealerCode
          })
          .then((response) => {
            setSubmitDisabled(false);
            if(response != null && response.data != undefined && response.data.success == true) {
                closeSignInForm();
                removeCookie('DealerDetails');
                setCookie('DealerDetails', response.data.data, { path: '/' });
                window.location.reload();
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
          }).catch(error => { // your error handling goes here};
            setSubmitDisabled(false);
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });
    }

    function signOutDealer() {
        removeCookie('DealerDetails');
        setDealerDetails({phone: '', dealerCode: ''});
        window.location.reload();
    }

    function requestDealerCode() {
        setSubmitCodeDisabled(true);
        axios.post(process.env.REACT_APP_API_URL + "dealers/requestcode", {
            Phone: requestCodeDetails.phone
          })
          .then((response) => {
            setSubmitCodeDisabled(false);
            if(response != null && response.data.success == true) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                closeSignInForm();
            }
            else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
          }).catch(error => { // your error handling goes here};
            setSubmitCodeDisabled(false);
            if (error.response) {
                if(error.response.data.message != undefined){
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.error(process.env.REACT_APP_COMMON_ERROR, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            //console.log(error.config);
          });

    }

    function redirectTo(page) {
        history.push("/" + page);
    }

    return (
        <div>
            <ToastContainer theme="colored"></ToastContainer>
            <header className="header">
                <div className="header-top">
                    <div className="mobile-header d-flex justify-content-between align-items-center d-xl-none">
                        <div className="all-catagory-option mobile-device">
                            &nbsp;
                        </div>
                        <Link to={'/'} className="logo"><img src="../web_logo.jpg" alt="logo"></img></Link>
                        <div>
                            <button className="menu-bar" type="button" data-toggle="modal" data-target="#menu-id">
                                Home<i className="fas fa-caret-down"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className="header-bottom">
                    <div className="row m-0 align-items-center mega-menu-relative">
                        <div className="col-md-2 p-0 d-none d-xl-block">
                            <div className="all-catagory-option">
                                <Link to={'/'}>
                                    <img src="../web_logo.jpg" alt="logo"></img>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="menu-area d-none d-xl-flex justify-content-between align-items-center">
                                <ul className="menu d-xl-flex flex-wrap list-unstyled">
                                    <li className="item-has-children">
                                            <Link to={'/'}>Home</Link>
                                    </li>
                                    <li><Link to={'/products'}>Products</Link></li>
                                    <li>
                                        <Link to={'/aboutus'}>About Us</Link>
                                    </li>
                                    <li>
                                        <Link to={'/contactus'}>Contact Us</Link>
                                    </li>
                                </ul>
                                <ul class="site-action d-none d-lg-flex align-items-center justify-content-between  ml-auto">
                                    <li className="site-phone mr-3"><a href="tel:000-000-000"><FontAwesomeIcon title="Phone" icon={faPhone} /> {webPhone}</a></li>
                                    {
                                        cookies.DealerDetails != undefined ? 
                                        (
                                            <li><FontAwesomeIcon icon={faUser} /> Welcome, {cookies.DealerDetails.name} !! <FontAwesomeIcon icon={faSignOutAlt} className="ml-2 site-link" color="#59b828"/><a className="site-link ml-1" onClick={signOutDealer}>Sign Out</a></li>
                                        ) : 
                                        (
                                            <li class="signin-option"><a onClick={openSignInForm} href="#"><FontAwesomeIcon icon={faUser} className="mr-2" />Dealer Sign In</a></li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="modal fade" id="menu-id" tabIndex="-1" aria-labelledby="menu-id" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <ul className="menu d-xl-flex flex-wrap pl-0 list-unstyled">
                                <li className="item-has-children">
                                    <a onClick={() => redirectTo('')} href="#" data-dismiss="modal" to={'/'}>
                                        Home
                                    </a>
                                </li>
                                <li><a onClick={() => redirectTo('products')} href="#" data-dismiss="modal">Products</a></li>
                                <li><a onClick={() => redirectTo('aboutus')} href="#" data-dismiss="modal">About Us</a></li>
                                <li><a onClick={() => redirectTo('contactus')} href="#" data-dismiss="modal">Contact Us</a></li>
                                {
                                    cookies.DealerDetails != undefined ? 
                                    (
                                        <li><FontAwesomeIcon icon={faUser} /> Welcome, {cookies.DealerDetails.name} !! <FontAwesomeIcon icon={faSignOutAlt} className="ml-2 site-link" color="#59b828"/><a className="mobile-signout site-link ml-1" href="#" onClick={signOutDealer}>Sign Out</a></li>
                                        
                                    ) :
                                    (
                                        <li data-dismiss="modal" class="signin-option"><a onClick={openSignInForm} href="#" className="mobile-signin"><FontAwesomeIcon icon={faUser} className="mr-2" />Dealer Sign In</a></li>
                                    )
                                }
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <section id="login-area" className="login-area">
                <div onClick={closeSignInForm} className="overlay"></div>
                <div className="login-body-wrapper">
                    <div className="login-body">
                        <div className="close-icon pointer" onClick={closeSignInForm}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                        {
                            !showForgotDealerCode ? 
                            (
                                <div>
                                    <div className="login-header">
                                        <h4>Sign in to your account (Dealer Only)</h4>
                                    </div>
                                    <div className="login-content">
                                        <div className="login-form">
                                            <input type="number" name="phone" value={dealerDetails.phone} onChange={handleChange} maxLength="10" placeholder="Mobile Number"></input>
                                            <div style={{position: "relative"}}>
                                                <input type={showPassword ? "text" : "password"} autoCapitalize="characters" value={dealerDetails.dealerCode} onChange={handleChange} name="dealerCode" maxLength="7" placeholder="Dealer Code [GFSXXXX]"></input>
                                                <FontAwesomeIcon icon={showPassword == false ? faEye : faEyeSlash } onClick={() => setShowPassword(prevState => !prevState)} className="password-eye pointer" />
                                            </div>
                                            <button type="submit" className="submit" onClick={dealerSignIn} disabled={submitDisabled}>Sign In</button>
                                        </div>
                                        <div className="text-center seperator">
                                            <span>Forgot Dealer Code? <a onClick={()=> setShowForgotDealerCode(true)} className="site-link">Click here</a></span>
                                        </div>
                                    </div>
                                </div>
                            ) : 
                            (
                                <div>
                                    <div className="login-header">
                                        <h4>Request Dealer Code</h4>
                                    </div>
                                    <div className="login-content">
                                        <div className="login-form">
                                            <input type="number" name="phone" value={requestCodeDetails.phone} onChange={handleCodeChange} maxLength="10" placeholder="Mobile Number"></input>
                                            <button type="submit" className="submit" disabled={submitCodeDisabled} onClick={requestDealerCode}>Request</button>
                                        </div>
                                        <div className="text-center seperator">
                                            <span><a className="site-link" onClick={()=> setShowForgotDealerCode(false)}>Back to Sing In</a></span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Header;