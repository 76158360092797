import axios from "axios";
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Slider, {slider} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $, {jQuery} from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function AllProducts() {

    const [productsData, setProductsData] = useState([]);
    const [selectedProductDetail, setSelectedProductDetail] = useState([{productImages: []}]);
    const [productSliderSettings, setProductSliderSettings] = useState({});
    const [productNavbarSliderSettings, setProductNavbarSliderSettings] = useState({});
    const [slider1, setSlider1] = useState();
    const [slider2, setSlider2] = useState();
    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    $('.product-list>.col-sm-6').hide();
    $('.product-list>.col-sm-6').slice(0,12).show();
    $('.loadMore').on("click",function(){
        $('.product-list>.col-sm-6:hidden').slice(0,4).show();
        if($('.product-list>.col-sm-6:hidden').length ==0){
            $(this).hide();
        }
    })

    useEffect(() => {
        loadAllProducts();
    },[]);

    

    function loadAllProducts() {
        axios.get(process.env.REACT_APP_API_URL + "products")
        .then(response => 
            {
                setProductsData(response.data.data);
            }
        )
        .catch(error => {
            if (error.response) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(process.env.REACT_APP_COMMON_ERROR, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        });
    }

    function openProductDetailsModal(productdetails) {
        document.getElementsByClassName('slick-track')[1].setAttribute("style", "left:0px;");

        productdetails.tick = new Date().getTime();
        setSelectedProductDetail(productdetails);
        setProductSliderSettings({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: slider2,
            className: 'product-slick',
            infinite: false
        });
        
        setProductNavbarSliderSettings({
            vertical: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: slider1,
            arrows: true,
            dots: false,
            focusOnSelect: true,
            className: 'slider-nav',
            infinite: false,
            centerMode: false
        });
    
        document.getElementById("product-details-popup").classList.add('open-side');
    }

    function closeProductDetailsModal() {
        setSelectedProductDetail({});
        document.getElementById("product-details-popup").classList.remove('open-side');
    }

    return (
        <div>
            <div className="page-header-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between justify-content-md-end">
                            <ul className="breadcrumb">
                                <li>
                                    <Link to={"/"}>
                                        Home
                                    </Link>
                                </li>
                                <li><span>/</span></li>
                                <li>Products</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <section className="page-content section-ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row product-list">
                                {productsData.map((product, index) => (
                                    <div className="col-sm-6 col-lg-4 col-xl-3" key={product.productId}>
                                        <div className="product-item">
                                            <div className="product-thumb">
                                                {product.productImages.length > 0 && product.productImages.filter(p=>p.imageType == 1).length > 0 ? product.productImages.map((productImage, index) => {
                                                    if(productImage.imageType == 1) {
                                                        return (<a style={{height: '255px', width: '255px'}} key={productImage.productImageId} onClick={() => {openProductDetailsModal(product)}}><img style={{maxHeight: '255px', maxWidth: '255px'}} src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/" + productImage.imageName} alt="product"></img></a>)
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                }): <a style={{height: '255px', width: '255px'}} onClick={() => {openProductDetailsModal(product)}}><img style={{maxHeight: '255px', maxWidth: '255px'}} src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/no_image.png"} alt="product"></img></a>}
                                            </div>
                                            <div className="product-content">
                                                <h6>
                                                    <Link to={"/productdetails/" + product.productId} className="product-title">
                                                        {product.name}
                                                    </Link>
                                                </h6>
                                                <p className="quantity">QTY: 1</p>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="price"><FontAwesomeIcon icon={faRupeeSign} />
                                                     { cookies.DealerDetails != undefined ? product.dealerPrice : product.retailPrice }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                <div className="col-12 text-center mt-4">
                                    <button className="loadMore">Load More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="product-details-popup" className="product-details-popup">
                <div className="modal-overlay" onClick={closeProductDetailsModal}></div>
                <div className="container">
                    <div className="product-zoom-info-container">
                        <div id="closed-modal" className="closed-modal" onClick={closeProductDetailsModal}>X</div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="product-zoom-area">
                                    <Slider {...productSliderSettings} ref={slider1 => (setSlider1(slider1))}>
                                        {selectedProductDetail != undefined && selectedProductDetail.productImages != undefined && selectedProductDetail.productImages.length > 0 ? selectedProductDetail.productImages.map((productImage, index) => {
                                            if(selectedProductDetail.productImages.length > 0) {
                                                return (<div key={selectedProductDetail.productId}><img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/" + productImage.imageName} alt={selectedProductDetail.name}
                                                    className="img-fluid blur-up lazyload image_zoom_cls-1"></img></div>)
                                            }
                                            else {
                                                return (<div><img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/no_image.png"} alt={selectedProductDetail.name}
                                                className="img-fluid blur-up lazyload image_zoom_cls-1"></img></div>)
                                            }
                                        }): 
                                            (<div><img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/no_image.png"} alt={selectedProductDetail.name}
                                                className="img-fluid blur-up lazyload image_zoom_cls-1"></img></div>)}
                                    </Slider>
                                    <div className="row">
                                        <div className="col-12">
                                            <Slider {...productNavbarSliderSettings} ref={slider2 => (setSlider2(slider2))}>
                                                {selectedProductDetail != undefined && selectedProductDetail.productImages != undefined && selectedProductDetail.productImages.length > 0 ? selectedProductDetail.productImages.map((productImage, index) => {
                                                    if(selectedProductDetail.productImages.length > 0) {
                                                        return (<div key={selectedProductDetail.productId}><img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/" + productImage.imageName} alt={selectedProductDetail.name}
                                                            className="img-fluid blur-up lazyload"></img></div>)
                                                    }
                                                    else {
                                                        return (<div><img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/no_image.png"} alt={selectedProductDetail.name}
                                                            className="img-fluid blur-up lazyload"></img></div>)
                                                    }
                                                }):
                                                    (<div><img src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + "/no_image.png"} alt={selectedProductDetail.name}
                                                    className="img-fluid blur-up lazyload"></img></div>)}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="product-details-content">
                                    <h2>{selectedProductDetail.name}</h2>
                                    <p className="quantity">QTY: 1</p>
                                            <h3 className="price"><FontAwesomeIcon icon={faRupeeSign} /> {
                                                cookies.DealerDetails != undefined ? selectedProductDetail.dealerPrice : selectedProductDetail.retailPrice
                                            }</h3>
                                        <p>{selectedProductDetail.description}</p>
                                    <div className="d-flex justify-content-end">
                                        <Link to={"/productdetails/" + selectedProductDetail.productId} className="buy-now">More Details</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default AllProducts;