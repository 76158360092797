import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <div>
            <div className="page-header-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between justify-content-md-end">
                            <ul className="breadcrumb">
                                <li><a href="index.html">Home</a></li>
                                <li><span>/</span></li>
                                <li>404 Page</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <section className="error-page text-center">
                <div className="container">
                    <h1>404</h1>
                    <h3>Page Not Found</h3>
                    <p>It looks like nothing was found at this location.</p>
                    <Link className="backhome" to={'/'}>Back Home</Link>
                </div>
            </section>
        </div>
    );
}
export default NotFound;